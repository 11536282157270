.loading-container {
    /* Center horizontally */
    text-align: center;
    /* Center vertically using flexbox */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Set the loading container to cover the full viewport */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7); /* Add a semi-transparent background */
  }
  
  .centered-content {
    /* Style for the loading animation and text */
    text-align: center;
  }