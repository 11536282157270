/* Reset margins and padding */
body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  }
  
  /* Ensure all elements respect box-sizing */
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  .header-container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  
  .navbar-custom {
    margin: 0;
    padding: 0;
  }
  
  .navbar-inner-container {
    margin: 0;
    padding: 0;
  }
  
  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var("bg-themeblue"); /* Replace with your actual theme blue color */
    width: 100%; /* Ensure the header takes full width */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
  }
  
  .navbar-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .navbar-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .icon-button-custom {
    margin: 0;
    padding: 0;
  }
  
  .fade-in {
    animation: fadeIn 1s forwards;
  }
  
  .fade-out {
    animation: fadeOut 1s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  