

.faq-style-wrapper .faq-title {
    font-size:xx-large;
    font-family: 'Poppins', sans-serif;
    flex: auto;
    justify-content: center !important;
    font-weight: bold;
    text-align: center; /* Add this line */
    padding-left: 12px; /* Add some space to the left */

}

.faq-style-wrapper .faq-body .faq-row {
    padding: 0.75em 0;
}

/* Add this to your existing Faq.css */


  

.faq-style-wrapper .faq-body .faq-row .row-title {
    font-size: larger;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    justify-content: center !important;
    text-align: center; /* Add this line */
    padding-left: 20px; /* Add some space to the left */

}

.faq-style-wrapper .faq-body .faq-row .row-content-text {
    padding: 2em !important;
    font-size: medium !important;
    color: black !important;
    font-family: 'Poppins', sans-serif;
    height: auto;
}

.row-option {
    margin-top: 2em;
}

.row-option label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5em;
}

.row-option input {
    padding: 0.5em;
    width: 2em;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.row-option button {
    padding: 0.5em 1em;
    margin-left: 1em;
    font-size: 1em;
    border-radius: 4px;
    background-color: #5000ca;
    border: 1px solid;
    color: white;
}

@media (max-width: 600px) {
    .faq-title {
      text-align: right;
    }
  }