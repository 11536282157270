
.sub-heading{
font-family: 'Poppins', sans-serif;
}


  
  /* Media query to hide herobg on screens with a width less than 957px */
  @media screen and (max-width: 956px) {
    .hide_img {
      display: none;
    }
  }


  
  /* Media query to adjust img width a width less than 1382px */
  @media screen and (max-width: 1382px) {
    .expand {
      width: 100%;
    }
  } 

  
  
  /* Media query to hide 4imgs on screens with a width less than 957px */
   @media screen and (max-width: 1382px) {
    .hide_4imgs {
      display: none;
    }
  } 


  
  /* Media query to adjust text container */
  @media screen and (max-width: 956px) {
    .full_width {
      width:100%;
      text-align: center;
      
    }
  } 

  /* Media query to prevent line breaks for widths greater than 956px */
@media screen and (min-width: 957px) {
    .no-line-break {
      white-space: nowrap;
    }
  }