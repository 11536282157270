
.sub-heading{
    font-family: 'San Serif', sans-serif;
    }


@media screen and (min-width: 1024px) {
.rounded-borders1{
    border-bottom-right-radius: 65px;
}
}



@media screen and (min-width: 1024px) {
.rounded-borders2{
    border-bottom-left-radius: 65px;
}
}

@media screen and (min-width: 1024px) {
.rounded-borders3{
    border-top-right-radius: 65px;
}
}

@media screen and (min-width: 1024px) {
.rounded-borders4{
    border-top-left-radius: 65px;
}
}

.shadow1{
    box-shadow: rgba(0, 0, 0, 0.15) 4px 4px 2.6px, rgba(0, 0, 0, 0.3) -0.1px -0.1px 2.6px;
}

.shadow2{
    box-shadow: rgba(0, 0, 0, 0.15) -4px 4px 2.6px, rgba(0, 0, 0, 0.3) 0.1px -0.1px 2.6px;



}

.shadow3{
   box-shadow: -0.8px 2px 2px rgba(0, 0, 0, 0.15), 4px -3px 2px rgba(0, 0, 0, 0.15);


}

.shadow4{
    box-shadow: rgba(0, 0, 0, 0.15) -4px -4px 2.6px, rgba(0, 0, 0, 0.15) 4px 4px 2.6px;

 
 }